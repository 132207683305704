<template>
  <div id="setting">
    <TopList :navList="navList"> </TopList>
    <ul class="list">
      <li class="item">
        <div class="title">账号信息</div>
        <div class="id">
          <div class="box">
            <div class="key">ID:</div>
            <div class="value">{{ userInfo.user.id }}</div>
          </div>
        </div>
        <div class="type">
          <div class="box">
            <div class="key">类型:</div>
            <div class="value">
              {{ userInfo.user.customerType | identity }}
            </div>
          </div>
          <div class="no" style="cursor: text">暂不支持修改</div>
        </div>
      </li>
      <li class="item">
        <div class="title">安全中心</div>
        <div v-if="isSon !== 'true'" class="type">
          <div class="box">
            <div class="key">手机号:</div>
            <div class="value">{{ userInfo.user.username }}</div>
          </div>
          <div class="yes" @click="set(0)">更改手机号</div>
        </div>
        <div class="type">
          <div class="box">
            <div class="key">登录密码:</div>
            <div class="value">{{ userInfo.user.password }}</div>
          </div>
          <div class="yes" @click="set(1)">修改</div>
        </div>
      </li>
    </ul>
    <Modal
      :scrollable="true"
      v-model="alert"
      class-name="vertical-center-modal"
      :footer-hide="true"
    >
      <div class="content">
        <Phone @setAlert="setAlert" v-if="type === 0"></Phone>
        <Pwd @setAlert="setAlert" v-if="type === 1"></Pwd>
      </div>
    </Modal>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Phone from "./components/phone";
import Pwd from "./components/pwd";
import TopList from "../../components/TopList";
import { getUserInfo } from "../../../../apis/user";

export default {
  name: "index",
  filters: {
    identity: function (num) {
      if (num === 1) return `普通用户`;
      if (num === 2) return `学者`;
      if (num === 3) return `专家`;
      if (num === 4) return `期刊`;
      if (num === 5) return `机构`;
    },
    subType: function (num) {
      if (num === 1) return `期刊子账号`;
      if (num === 2) return `机构子账号`;
      return "子账号";
    },
  },
  components: { Phone, Pwd, TopList },
  computed: {
    ...mapState(["userInfo"]),
  },
  data() {
    return {
      alert: false,
      type: 0,
      isSon: false,
      navList: ["账号设置"],
    };
  },
  methods: {
    set(num) {
      this.type = num;
      this.alert = true;
    },
    setAlert() {
      this.alert = false;
    },
    async getUserInfo() {
      let data = await getUserInfo();
      console.log(data, "data");
      let userObj = {
        token: sessionStorage.getItem("token"),
        hasLogin: true,
        userInfo: data.content,
      };
      // console.log(obj);
      this.$store.commit("loginInfo", userObj);
    },
  },
  created() {
    this.getUserInfo();
    console.log(this.userInfo.user, "this.userInfo");
    this.isSon = sessionStorage.getItem("isSon");
  },
};
</script>

<style lang="scss" scoped>
::v-deep .ivu-modal {
  width: 500px;
  background: #ffffff;
  border-radius: 18px;
  .ivu-modal-content {
    border-radius: 18px;
    .ivu-modal-close {
      width: 50px;
      height: 50px;
      line-height: 50px;
      text-align: center;
      background: #00A4FF;
      border-radius: 0px 18px 0px 18px;
      top: 0;
      right: 0;
      overflow: hidden;
      .ivu-icon-ios-close {
        font-size: 26px;
        color: #FFFFFF;
      }
    }
  }
}
#setting {
  min-height: 427px;
  background: #ffffff;
  border: 1px dotted #999999;
  border-radius: 10px;
  .list {
    .item {
      //margin-top: 10px;
      //width: 1200px;
      padding-bottom: 30px;
      //height: 159px;
      padding-top: 34px;
      height: 187px;
      background: #ffffff;
      padding-left: 42px;
      border-bottom: 1px dashed #d6d6d6;

      .no {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #666666;
        cursor: pointer;
        margin-left: 40px;
      }

      .yes {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #0b35c5;
        cursor: pointer;
        margin-left: 40px;
      }

      .title {
        width: 120px;
        height: 22px;
        font-size: 22px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #333333;
        line-height: 24px;
        margin-bottom: 30px;
      }

      .id {
        //margin-top: 30px;
        .box {
          display: flex;

          .key {
            width: 100px;
            text-align: right;
            //background: pink;
          }

          .value {
            width: 200px;
            margin-left: 60px;
          }
        }
      }

      .type {
        width: 95%;
        //background: yellow;
        display: flex;
        //justify-content: space-between;
        margin-top: 10px;

        .box {
          display: flex;

          .key {
            text-align: right;
            width: 100px;
            //background: pink;
          }

          .value {
            width: 100px;
            margin-left: 60px;
          }
        }
      }
    }
  }
}
</style>
