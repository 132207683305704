<template>
  <div id="pwd">
    <div class="title">消息详情</div>
    <ul v-if="isSonUser !== 'true'" class="from">
      <li class="item">
        <div class="value">
          您原有的手机号是<span style="color: #00a4ff">{{
            userInfo.user.username | jiami
          }}</span
          >，请验证手机号。
        </div>
      </li>
      <li class="item">
        <Input v-model.trim="phoneKey" placeholder="请输入手机验证码" />
        <input type="text" class="elHidden" />
        <div @click="getCode()" :class="keyFlag ? 'getCode' : 'nullCode'">
          {{ keyButton }}
        </div>
      </li>
      <li class="item">
        <div class="value">
          <Input
            placeholder="设置新密码"
            v-model="setPwd"
            type="text"
            :class="showEye ? '' : 'no-autofill-pwd'"
            @on-click="iconClick"
          />
          <input type="text" class="elHidden" />
        </div>
      </li>
      <li class="item">
        <div class="value">
          <Input
            placeholder="确认新密码"
            v-model="setPwd2"
            type="text"
            :class="showEye ? '' : 'no-autofill-pwd'"
            @on-click="iconClick"
          />
          <input type="text" class="elHidden" />
        </div>
      </li>
    </ul>
    <ul v-else class="from">
      <li class="item">
        <div class="value">
          <Input
            v-model="sonFrom.upPwd"
            style="width: 370px"
            type="text"
            :icon="showEye ? 'ios-eye-outline' : 'ios-eye-off-outline'"
            :class="showEye ? '' : 'no-autofill-pwd'"
            @on-click="iconClick"
          />
          <input type="text" class="elHidden" />
        </div>
      </li>
      <li class="item">
        <div class="value">
          <Input
            v-model="sonFrom.newPwd"
            style="width: 370px"
            type="text"
            :icon="showEye ? 'ios-eye-outline' : 'ios-eye-off-outline'"
            :class="showEye ? '' : 'no-autofill-pwd'"
            @on-click="iconClick"
          />
          <input type="text" class="elHidden" />
        </div>
      </li>
      <li class="item">
        <div class="value">
          <Input
            v-model="sonFrom.newPwd2"
            style="width: 370px"
            type="text"
            :icon="showEye ? 'ios-eye-outline' : 'ios-eye-off-outline'"
            :class="showEye ? '' : 'no-autofill-pwd'"
            @on-click="iconClick"
          />
          <input type="text" class="elHidden" />
        </div>
      </li>
    </ul>
    <div class="btn" slot="footer">
      <Button @click="closeAlert" class="close">取消</Button>
      <Button
        class="affirm"
        @click="affirmAlert"
        type="primary"
        v-throttleClick="2000"
        >确认</Button
      >
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "pwd",
  filters: {
    jiami(val) {
      let reg = /(\d{3})\d{4}(\d{4})/; //正则表达式
      return val.replace(reg, "$1****$2");
    },
  },
  computed: {
    isSonUser() {
      return sessionStorage.getItem("isSon");
    },
    ...mapState(["userInfo"]),
  },
  data() {
    return {
      phoneKey: "",
      setPwd: "",
      setPwd2: "",
      keyButton: "发送验证码",
      keyFlag: true,
      timer: null,
      sonFrom: {
        upPwd: "",
        newPwd: "",
        newPwd2: "",
      },
      showEye: false,
    };
  },
  methods: {
    iconClick() {
      this.showEye = !this.showEye;
    },
    closeAlert() {
      (this.phoneKey = ""),
        (this.setPwd = ""),
        (this.setPwd2 = ""),
        (this.sonFrom = {
          upPwd: "",
          newPwd: "",
          newPwd2: "",
        }),
        (this.showEye = false);
      this.$emit("setAlert");
    },
    async affirmAlert() {
      if (this.phoneKey == "") {
        this.$Message.error("手机验证码不能为空");
        return;
      }
      if (this.setPwd == "") {
        this.$Message.error("新密码不能为空");
        return;
      }
      if (this.setPwd2 == "") {
        this.$Message.error("确认新密码不能为空");
        return;
      }
      if (sessionStorage.getItem("isSon") !== "true") {
        if (this.setPwd !== this.setPwd2) {
          this.$Message.error("两次密码不一致");
          return;
        }
        // this.$emit("setAlert");
        let obj = {
          pwd: this.setPwd,
          validateCode: this.phoneKey,
        };
        const res = await this.$apis.userServe.setPwd(obj);
        if (res.resultCode == 200) {
          this.$Message.success(res.resultDesc);
          this.closeAlert();
        } else {
          this.$Message.error(res.resultDesc);
          return;
        }
      } else {
        if (this.sonFrom.newPwd !== this.sonFrom.newPwd2) {
          this.$Message.error("两次密码不一致");
          return;
        }
        let obj = {
          oldPwd: this.sonFrom.upPwd,
          pwd: this.sonFrom.newPwd2,
        };

        const res = await this.$apis.userServe.setSonPwd(obj);
        if (res.resultCode == 200) {
          this.$Message.success(res.resultDesc);
          this.closeAlert();
        } else {
          this.$Message.error(res.resultDesc);
          return;
        }
      }
    },
    destroy() {
      clearInterval(this.timer);
      this.keyFlag = true;
      this.keyButton = "获取验证码";
    },
    getCode() {
      if (!this.keyFlag) return;
      this.keyFlag = !this.keyFlag;
      let num = 60;
      this.sendCode();
      this.keyButton = `${num}秒后重新获取`;
      this.timer = setInterval(() => {
        if (num <= 0) {
          this.destroy();
        } else {
          this.keyButton = `${--num}秒后重新获取`;
        }
      }, 1000);
    },
    sendCode() {
      let obj = {
        phone: this.userInfo.user.username,
        adapter: "reset_pwd",
      };
      this.$apis.loginServe.getCode(obj);
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .ivu-input-wrapper {
  width: auto;
}
.item:nth-child(3) ::v-deep .ivu-input{
  width: 420px;
}
.item:nth-child(4) ::v-deep .ivu-input{
  width: 420px;
}
::v-deep .ivu-input {
  width: 300px;
  height: 40px;
  background: #ffffff;
  border: 1px solid #999999;
  border-radius: 8px;
}
#pwd {
  //padding-left: 50px;
  padding-top: 38px;
  .btn {
    margin-top: 34px;
    padding-right: 36px;
    width: 100%;
    text-align: right;
    .close {
      text-align: center;
      line-height: 38px;
      width: 72px;
      height: 38px;
      color: #007fff;
      background: #ffffff;
      border: 1px dotted #007fff;
      border-radius: 8px;
      margin-right: 20px;
      cursor: pointer;
    }
    .affirm {
      text-align: center;
      line-height: 38px;
      width: 70px;
      height: 36px;
      color: #fff;
      background: #007fff;
      border-radius: 8px;
      cursor: pointer;
    }
  }
  .from {
    margin-left: 30px;
    margin-top: 35px;
    .item {
      margin: 20px 0;
      //background: pink;
      height: 40px;
      //width: 600px;
      display: flex;
      .value {
        line-height: 40px;
      }
      .key {
        width: 80px;
        line-height: 32px;
        text-align: right;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #333333;
        white-space: nowrap;
      }
      .elHidden {
        width: 0;
        opacity: 0;
      }
      .no-autofill-pwd {
        ::v-deep .ivu-input-default {
          -webkit-text-security: disc !important;
        }
      }
      .getCode {
        width: 110px;
        height: 40px;
        background: #00a4ff;
        border-radius: 8px;
        color: #fff;
        margin-left: 10px;
        line-height: 40px;
        text-align: center;
        cursor: pointer;
      }
      .nullCode {
        width: 110px;
        height: 40px;
        line-height: 40px;
        margin-left: 20px;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #333;
      }
    }
  }
  .title {
    font-size: 24px;
    margin-left: 30px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #333333;
  }
}
</style>
