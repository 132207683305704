<template>
  <div id="phone">
    <div class="title">更改手机号</div>
    <ul class="from">
      <li class="item">
        <div class="key">原手机号</div>
        <div class="value">{{ userInfo.user.username }}</div>
      </li>
      <li class="item">
        <div class="key">新手机号</div>
        <div class="value">
          <Input v-model="newPhone" placeholder="" style="width: 300px" />
          <input type="text" class="elHidden" />
        </div>
      </li>
      <li class="item">
        <div class="key">新手机验证码</div>
        <div class="value">
          <Input v-model="newPhoneKey" placeholder="" style="width: 200px" />
          <input type="text" class="elHidden" />

          <Button
            type="text"
            @click="newKeyClick"
            :class="newKeyFlag ? 'getCode' : 'nullCode'"
            v-throttleClick="2000"
          >
            {{ newKeyButton }}</Button
          >
        </div>
      </li>
      <li class="item">
        <div class="key">设置新密码</div>
        <div class="value">
          <Input
            v-model="setPwd"
            style="width: 300px"
            type="text"
            :icon="showEye ? 'ios-eye-outline' : 'ios-eye-off-outline'"
            :class="showEye ? '' : 'no-autofill-pwd'"
            @on-click="iconClick"
          />
          <input type="text" class="elHidden" />
        </div>
      </li>
    </ul>
    <div class="btn" slot="footer">
      <Button @click="closeAlert" class="close">取消</Button>
      <Button
        class="affirm"
        @click="affirm"
        type="primary"
        v-throttleClick="2000"
        >确认</Button
      >
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "phone",
  computed: {
    ...mapState(["userInfo"]),
  },
  filters: {
    jiami(val) {
      let reg = /(\d{3})\d{4}(\d{4})/; //正则表达式
      return val.replace(reg, "$1****$2");
    },
  },
  data() {
    return {
      phoneKey: "",
      newPhone: "",
      newPhoneKey: "",
      keyButton: "获取手机验证码",
      timer: null,
      keyFlag: true,
      newKeyButton: "获取新手机验证码",
      newKeyFlag: true,
      newTimer: null,
      setPwd: "",
      showEye: false,
    };
  },
  methods: {
    iconClick() {
      this.showEye = !this.showEye;
    },
    async affirm() {
      if (this.newPhone == "") {
        this.$Message.error("新手机号不能为空");
        return;
      }
      if (this.newPhoneKey == "") {
        this.$Message.error("新手机验证码不能为空");
        return;
      }
      if (this.setPwd == "") {
        this.$Message.error("新密码不能为空");
        return;
      }
      let obj = {
        mobile: this.newPhone,
        validateCode: this.newPhoneKey,
        pwd: this.setPwd,
      };
      console.log(obj);
      const res = await this.$apis.userServe.setPhone(obj);
      if (res.resultCode == 200) {
        this.$Message.success(res.resultDesc);
        this.closeAlert();
      } else {
        this.$Message.error(res.resultDesc);
        return;
      }
    },
    closeAlert() {
      (this.newPhone = ""),
        (this.newPhoneKey = ""),
        (this.setPwd = ""),
        (this.showEye = false),
        this.$emit("setAlert");
    },
    destroy() {
      clearInterval(this.timer);
      this.keyFlag = true;
      this.keyButton = "获取手机验证码";
    },
    newDestroy() {
      clearInterval(this.newTimer);
      this.newKeyFlag = true;
      this.newKeyButton = "获取验证码";
    },
    keyClick() {
      if (!this.keyFlag) {
        return;
      }
      this.keyFlag = !this.keyFlag;
      let num = 60;
      this.sendCode("old");
      this.keyButton = `${num}秒后重新获取`;
      this.timer = setInterval(() => {
        if (num <= 0) {
          this.destroy();
        } else {
          this.keyButton = `${--num}秒后重新获取`;
        }
      }, 1000);
    },
    sendCode(type) {
      console.log(this.$apis.loginServe);
      let obj = {
        phone: type === "new" ? this.newPhone : this.userInfo.user.username,
        adapter: "reset_pwd",
      };
      this.$apis.loginServe.getCode(obj);
      console.log(type);
    },
    newKeyClick() {
      if (this.newPhone == "") {
        this.$Message.error("新手机号不能为空");
        return;
      }
      if (!this.newKeyFlag) {
        return;
      }
      const pattern =
        /^0?(13[0-9]|15[012356789]|17[013678]|18[0-9]|14[57])[0-9]{8}$/;
      if (!pattern.test(this.newPhone)) {
        this.$Message.error("手机号格式错误");
        return;
      }
      if (this.newPhone === this.userInfo.user.username) {
        this.$Message.error("请输入新手机号");
        return;
      }
      this.sendCode("new");
      this.newKeyFlag = !this.newKeyFlag;
      let num = 60;
      this.newKeyButton = `${num}秒后重新获取`;
      this.newTimer = setInterval(() => {
        if (num <= 0) {
          this.newDestroy();
        } else {
          this.newKeyButton = `${--num}秒后重新获取`;
        }
      }, 1000);
    },
  },
};
</script>

<style lang="scss" scoped>
#phone {
  //padding-left: 50px;
  padding-top: 38px;
  .btn {
    margin-top: 34px;
    padding-right: 50px;
    width: 100%;
    text-align: right;
    .close {
      text-align: center;
      line-height: 38px;
      width: 72px;
      height: 38px;
      background: #ffffff;
      border: 1px dotted #007fff;
      color: #007fff;
      border-radius: 8px;
      margin-right: 20px;
      cursor: pointer;
    }
    .affirm {
      text-align: center;
      line-height: 34px;
      width: 70px;
      height: 36px;
      color: #fff;
      background: #007fff;
      border-radius: 8px;
      cursor: pointer;
    }
  }
  .from {
    margin-left: 16px;
    margin-top: 35px;
    .item {
      margin-top: 10px;
      margin-bottom: 10px;
      height: 32px;
      display: flex;
      .key {
        width: 100px;
        line-height: 32px;
        text-align: right;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #333333;
      }
      .value {
        display: flex;
        line-height: 32px;
        margin-left: 20px;
        .no-autofill-pwd {
          ::v-deep .ivu-input-default {
            -webkit-text-security: disc !important;
          }
        }
        .elHidden {
          width: 0;
          opacity: 0;
        }
        .getCode {
          margin-left: 20px;
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #007fff;
          cursor: pointer;
          padding: 0;
        }
        .nullCode {
          cursor: pointer;
          margin-left: 20px;
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #333;
          padding: 0;
        }
      }
    }
  }
  .title {
    font-size: 24px;
    margin-left: 50px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #333333;
  }
}
</style>
